import { render, staticRenderFns } from "./idnex.vue?vue&type=template&id=3f7c1d70&scoped=true&"
import script from "./idnex.vue?vue&type=script&lang=js&"
export * from "./idnex.vue?vue&type=script&lang=js&"
import style0 from "./idnex.vue?vue&type=style&index=0&id=3f7c1d70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f7c1d70",
  null
  
)

export default component.exports